<template>
  <div class="form-stepper form-stepper-contract">
    <v-form
      ref="form"
      v-model="valid"
      class="form pa-6"
    >
      <v-subheader class="pb-4 pl-0">
        <v-icon class="mr-2" size="16">mdi-graph-outline</v-icon>
        {{ $t('JOB.SUBTITLE_CONTRACT_SECTOR') }}
      </v-subheader>

      <v-row class="ma-0">
        <v-col class="pa-0 pl-1 pb-4">
          <v-autocomplete
            prepend-inner-icon="mdi-shape"
            v-model="xtramile_sector_id"
            :items="sectorItems"
            item-value="value"
            item-text="text"
            required
            hide-details
            :label="$t('JOB.FIELD_SECTOR')"
            :placeholder="$t('JOB.FIELD_SECTOR_PLACEHOLDER')"
            outlined
            dense
          />
        </v-col>
      </v-row>

      <v-subheader class="pb-4 pl-0">
        <v-icon class="mr-2" size="16">mdi-clipboard-text-outline</v-icon>
        {{ $t('JOB.SUBTITLE_CONTRACT_CONDITIONS') }}
      </v-subheader>

      <v-row class="ma-0">
        <v-col cols="4" class="pa-0">
          <v-select
            dense
            v-model="contract_type"
            :items="contractTypeItems"
            :label="$t('INPUT.CONTRACT_TYPE')"
            :placeholder="$t('INPUT.CONTRACT_TYPE')"
            item-value="value"
            item-text="text"
            outlined
          />
        </v-col>
        <v-col cols="4" class="pa-0 pl-1">
          <v-select
            prepend-inner-icon="mdi-clock"
            dense
            v-model="contract_schedule"
            :items="contractScheduleItems"
            :label="$t('INPUT.CONTRACT_SCHEDULE')"
            :placeholder="$t('INPUT.CONTRACT_SCHEDULE')"
            item-value="value"
            item-text="text"
            outlined
          />
        </v-col>
        <v-col cols="4" class="pa-0 pl-4">
          <v-checkbox
          class="pt-0 mt-2"
          v-model="contract_remote"
          :label="$t('INPUT.CONTRACT_REMOTE')"
          />
        </v-col>
      </v-row>

      <v-subheader class="pb-4 pl-0">
        <v-icon class="mr-2" size="16">mdi-cash-multiple</v-icon>
        {{ $t('JOB.SUBTITLE_CONTRACT_SALARY') }}
      </v-subheader>

      <v-row class="ma-0">
        <v-col cols="2" class="pa-0">
          <v-text-field
            dense
            v-model="salary_min"
            :rules="[$rules.min(0)]"
            :label="$t('INPUT.MIN')"
            :placeholder="$t('INPUT.MIN')"
            type="number"
            min="0"
            outlined
          />
        </v-col>
        <v-col cols="1" class="text-center pt-2"><v-icon size="18">mdi-arrow-left-right</v-icon></v-col>
        <v-col cols="2" class="pa-0">
          <v-text-field
            dense
            v-model="salary_max"
            :rules="[$rules.min(0)]"
            :label="$t('INPUT.MAX')"
            :placeholder="$t('INPUT.MAX')"
            type="number"
            min="0"
            outlined
          />
        </v-col>
        <v-col cols="1" class="text-center pt-2"><v-icon size="18">mdi-slash-forward</v-icon></v-col>
        <v-col cols="3" class="pa-0">
          <v-select
            dense
            v-model="salary_period"
            :items="periodItems"
            :label="$t('INPUT.PERIOD')"
            :placeholder="$t('INPUT.PERIOD')"
            item-value="value"
            item-text="text"
            outlined
          />
        </v-col>
        <v-col cols="3" class="pa-0 pl-1">
          <v-select
            dense
            v-model="salary_currency"
            :items="salaryCurrencyItems"
            :label="$t('INPUT.CURRENCY')"
            :placeholder="$t('INPUT.CURRENCY')"
            item-value="value"
            item-text="text"
            outlined
          />
        </v-col>
      </v-row>

      <v-subheader class="pb-4 pl-0">
        <v-icon class="mr-2" size="16">mdi-clock</v-icon>
        {{ $t('JOB.SUBTITLE_CONTRACT_DURATION') }}
      </v-subheader>

      <v-row class="ma-0">
        <v-col cols="2" class="pa-0">
          <v-text-field
            dense
            v-model="duration_min"
            :rules="[$rules.min(0)]"
            :label="$t('INPUT.MIN')"
            :placeholder="$t('INPUT.MIN')"
            type="number"
            min="0"
            outlined
          />
        </v-col>
        <v-col cols="1" class="text-center pt-2"><v-icon size="18">mdi-arrow-left-right</v-icon></v-col>
        <v-col cols="2" class="pa-0">
          <v-text-field
            dense
            v-model="duration_max"
            :rules="[$rules.required, $rules.min(0)]"
            :label="$t('INPUT.MAX')"
            :placeholder="$t('INPUT.MAX')"
            type="number"
            min="0"
            outlined
            required
          />
        </v-col>
        <v-col cols="1" class="text-center pt-2"><v-icon size="18">mdi-slash-forward</v-icon></v-col>
        <v-col cols="3" class="pa-0">
          <v-select
            dense
            v-model="duration_period"
            :items="periodItems"
            :label="$t('INPUT.PERIOD')"
            :placeholder="$t('INPUT.PERIOD')"
            item-value="value"
            item-text="text"
            outlined
          />
        </v-col>
      </v-row>

      <v-subheader class="pb-4 pl-0">
        <v-icon class="mr-2" size="16">mdi-weight-lifter</v-icon>
        {{ $t('JOB.SUBTITLE_CONTRACT_EXPERIENCE') }}
      </v-subheader>

      <v-row class="ma-0">
        <v-col cols="2" class="pa-0">
          <v-text-field
            dense
            v-model="experience_min"
            :rules="[$rules.min(0)]"
            :label="$t('INPUT.MIN')"
            :placeholder="$t('INPUT.MIN')"
            type="number"
            min="0"
            outlined
          />
        </v-col>
        <v-col cols="1" class="text-center pt-2"><v-icon size="18">mdi-arrow-left-right</v-icon></v-col>
        <v-col cols="2" class="pa-0">
          <v-text-field
            dense
            v-model="experience_max"
            :rules="[$rules.min(0)]"
            :label="$t('INPUT.MAX')"
            :placeholder="$t('INPUT.MAX')"
            type="number"
            min="0"
            outlined
          />
        </v-col>
        <v-col cols="1" class="text-center pt-2"><v-icon size="18">mdi-slash-forward</v-icon></v-col>
        <v-col cols="3" class="pa-0">
          <v-select
            dense
            v-model="experience_period"
            :items="periodItems"
            :label="$t('INPUT.PERIOD')"
            :placeholder="$t('INPUT.PERIOD')"
            item-value="value"
            item-text="text"
            outlined
          />
        </v-col>
        <v-col cols="3" class="pa-0 pl-1">
          <v-select
            dense
            v-model="experience_level"
            :items="experienceLevelItems"
            :label="$t('INPUT.EXPERIENCE')"
            :placeholder="$t('INPUT.EXPERIENCE')"
            item-value="value"
            item-text="text"
            outlined
          />
        </v-col>
      </v-row>

      <v-row class="ma-0">
        <v-col cols="12" class="pa-0">
          <v-autocomplete
            dense
            prepend-inner-icon="mdi-playlist-check"
            no-filter
            v-model="experience_domains"
            :items="domainsItems"
            :search-INPUT.sync="domainsInput"
            :label="$t('INPUT.DOMAINS')"
            :placeholder="$t('INPUT.DOMAINS')"
            outlined
            return-object
            multiple
          />
        </v-col>
      </v-row>

      <v-subheader class="pb-4 pl-0"><v-icon class="mr-2" size="16">mdi-school</v-icon>Education</v-subheader>

      <v-row class="ma-0">
        <v-col cols="4" class="pa-0">
          <v-select
            dense
            v-model="education_level"
            :items="educationLevelItems"
            :label="$t('INPUT.EDUCATION')"
            :placeholder="$t('INPUT.EDUCATION')"
            item-value="value"
            item-text="text"
            outlined
          />
        </v-col>
        <v-col cols="4" class="pa-0 pl-1">
          <v-select
            dense
            class="autocomplete-wrap"
            v-model="education_languages"
            :items="educationLanguageItems"
            :label="$t('INPUT.LANGUAGE')"
            :placeholder="$t('INPUT.LANGUAGE')"
            item-value="value"
            item-text="text"
            outlined
            multiple
          />
        </v-col>
      </v-row>

      <v-row class="ma-0">
        <v-col cols="12" class="pa-0">
          <v-autocomplete
            dense
            prepend-inner-icon="mdi-certificate"
            no-filter
            v-model="diplomas"
            :items="diplomasItems"
            :search-INPUT.sync="diplomasInput"
            :label="$t('JOB.DIPLOMA')"
            :placeholder="$t('JOB.DIPLOMA')"
            outlined
            return-object
            multiple
          />
        </v-col>
      </v-row>

      <v-row class="ma-0">
        <v-col cols="12" class="pa-0">
          <v-autocomplete
            dense
            prepend-inner-icon="mdi-playlist-check"
            no-filter
            v-model="education_domains"
            :items="domainsItems"
            :search-INPUT.sync="domainsInput"
            :label="$t('INPUT.DOMAINS')"
            :placeholder="$t('INPUT.DOMAINS')"
            outlined
            return-object
            multiple
          />
        </v-col>
      </v-row>
    </v-form>
    <v-divider />

    <v-toolbar
      color="white"
      flat
      height="70px"
      class="form-footer"
    >
      <v-spacer />
      <v-btn
        color="secondary custom black--text"
        class="mr-4"
        rounded
        depressed
        :disabled="loading"
        large
        @click="pass"
      >
        <strong>{{$t('COMMON.PASS')}}</strong>
        <v-icon class="ml-2">mdi-redo</v-icon>
      </v-btn>

      <v-btn
        color="primary custom"
        class="mr-4"
        rounded
        depressed
        :disabled="!valid && !loading"
        large
        @click="validate"
      >
        <strong>{{ $t('CONTINUE') }}</strong>
        <v-icon class="ml-2">mdi-arrow-right</v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>


<script>
export default {
  name: 'FormJobStepContract',
  data: (_t) => ({
    loading: false,
    valid: false,
    sectorItems: _t.$i18n.t('JOB.XTRAMILE_SECTORS_ITEMS').sort((a, b) => a.text.localeCompare(b.text)),

    periodItems: _t.$i18n.t('JOB.PERIOD_ITEMS'),
    currencyItems: _t.$i18n.t('JOB.CURRENCY_ITEMS'),
    experienceLevelItems: _t.$i18n.t('JOB.EXPERIENCE_LEVEL_ITEMS'),
    educationLevelItems: _t.$i18n.t('JOB.EDUCATION_LEVEL_ITEMS'),
    contratStatusItems: _t.$i18n.t('JOB.CONTRACT_STATUS_ITEMS'),
    contractTypeItems: _t.$i18n.t('JOB.CONTRACT_TYPES_ITEMS'),

    // contractTypeItems: [
    //   {text: _t.$i18n.t('INPUT.CONTRACT_TYPE_PERMANENT'), value: 'permanent'},
    //   {text: _t.$i18n.t('INPUT.CONTRACT_TYPE_FIXED'), value: 'fixed'},
    //   {text: _t.$i18n.t('INPUT.CONTRACT_TYPE_INTERIM'), value: 'interim'},
    //   {text: _t.$i18n.t('INPUT.CONTRACT_TYPE_INTERNSHIP'), value: 'internship'},
    //   {text: _t.$i18n.t('INPUT.CONTRACT_TYPE_WORK_STUDY'), value: 'work_study'},
    //   {text: _t.$i18n.t('INPUT.CONTRACT_TYPE_OFFICIAL'), value: 'official'},
    //   {text: _t.$i18n.t('INPUT.CONTRACT_TYPE_FREELANCE'), value: 'freelance'},
    //   {text: _t.$i18n.t('INPUT.CONTRACT_TYPE_SEASONAL'), value: 'seasonal'},
    //   {text: _t.$i18n.t('INPUT.CONTRACT_TYPE_INTERMITTENT'), value: 'intermittent'},
    // ],
    contractScheduleItems: [
      {text: _t.$i18n.t('INPUT.CONTRACT_SCHEDULE_FULL_TIME'), value: 'fulltime'},
      {text: _t.$i18n.t('INPUT.CONTRACT_SCHEDULE_PART_TIME'), value: 'parttime'},
      {text: _t.$i18n.t('INPUT.CONTRACT_SCHEDULE_FULL_PART_TIME'), value: 'fullparttime'},
    ],
    domainsItems: [
      {text: _t.$i18n.t('INPUT.DOMAINS_SALE'), value: 'sale'},
    ],
    domainsInput: '',
    // periodItems: [
    //   {text: _t.$i18n.t('INPUT.PERIOD_DAY'), value: 'day'},
    //   {text: _t.$i18n.t('INPUT.PERIOD_MONTH'), value: 'month'},
    //   {text: _t.$i18n.t('INPUT.PERIOD_YEAR'), value: 'year'}
    // ],
    salaryCurrencyItems: [
      {text: _t.$i18n.t('INPUT.CURRENCY_EUR'), value: 'eur'},
      {text: _t.$i18n.t('INPUT.CURRENCY_USD'), value: 'usd'},
    ],
    // experienceLevelItems: [
    //   {text: _t.$i18n.t('INPUT.EXPERIENCE_NONE'), value: null},
    //   {text: _t.$i18n.t('INPUT.EXPERIENCE_BEGINNER'), value: 'beginner'},
    //   {text: _t.$i18n.t('INPUT.EXPERIENCE_JUNIOR'), value: 'junior'},
    //   {text: _t.$i18n.t('INPUT.EXPERIENCE_EXPERIENCED'), value: 'experienced'},
    //   {text: _t.$i18n.t('INPUT.EXPERIENCE_SENIOR'), value: 'senior'},
    // ],
    // educationLevelItems: [
    //   {text: _t.$i18n.t('INPUT.EDUCATION_VOCATIONAL'), value: 'vocationnal'},
    //   {text: _t.$i18n.t('INPUT.EDUCATION_HIGHSCHOOL'), value: 'highschool'},
    //   {text: _t.$i18n.t('INPUT.EDUCATION_ASSOCIATE'), value: 'associate'},
    //   {text: _t.$i18n.t('INPUT.EDUCATION_BACHELOR'), value: 'bachelor'},
    //   {text: _t.$i18n.t('INPUT.EDUCATION_EXECUTIVE_BACHELOR'), value: 'executive_bachelor'},
    //   {text: _t.$i18n.t('INPUT.EDUCATION_MASTER'), value: 'master'},
    //   {text: _t.$i18n.t('INPUT.EDUCATION_EXECUTIVE_MASTER'), value: 'executive_master'},
    //   {text: _t.$i18n.t('INPUT.EDUCATION_DOCTORANT'), value: 'doctorant'},
    //   {text: _t.$i18n.t('INPUT.EDUCATION_EXECUTIVE_DOCTORANT'), value: 'executive_doctorant'},
    // ],
    educationLanguageItems: [
      {text: _t.$i18n.t('INPUT.LANGUAGE_FR'), value: 'fr'},
      {text: _t.$i18n.t('INPUT.LANGUAGE_EN'), value: 'en'},
      {text: _t.$i18n.t('INPUT.LANGUAGE_DE'), value: 'de'},
      {text: _t.$i18n.t('INPUT.LANGUAGE_DE'), value: 'du'},
      {text: _t.$i18n.t('INPUT.LANGUAGE_IT'), value: 'it'},
      {text: _t.$i18n.t('INPUT.LANGUAGE_ES'), value: 'es'},
      {text: _t.$i18n.t('INPUT.LANGUAGE_RU'), value: 'ru'},
      {text: _t.$i18n.t('INPUT.LANGUAGE_CN'), value: 'cn'},
    ],
    diplomasInput: '',
    diplomasItems: [],

    //
    xtramile_sector_id: null,
    duration: null,
    duration_min: '',
    duration_max: '',
    duration_period: 'year',
    // duration_min_period: null,
    // duration_max_period: null,
    salary: null,
    salary_min: '',
    salary_max: '',
    salary_period: 'year',
    salary_currency: 'eur',
    contract_schedule: null,
    contract_type: null,
    contract_remote: false,
    contract_status: null,
    experience_level: null,
    experience_min: '',
    experience_max: '',
    experience_period: 'year',
    // experience_min_period: null,
    // experience_max_period: null,
    experience_domains: [],
    diplomas: null,
    education: null,
    education_level: null,
    education_languages: [],
    education_domains: [],

  }),
  props: {
    values: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    values (n) {
      this.init()
    },
  },
  mounted () {
    this.init()
  },
  methods: {

    init () {
      Object.keys(this.values).forEach((key, i) => {
        if (key === 'salary_min_period') {
          this.salary_period = this.values[key]
        } else if (key === 'duration_min_period') {
          this.duration_period = this.values[key]
        } else if (typeof this.values[key] !== 'undefined' && typeof this[key] !== 'undefined') {
          this[key] = this.values[key]
        }
      })
    },

    pass () {
      this.$emit('validation', null)
      this.$emit('next')
    },

    validate () {
      if (this.$refs.form.validate()) {
        const data = {}

        if (this.duration_min) {
          data.duration_min = this.duration_min
          if (this.duration_max) data.duration_max = this.duration_max
          if (this.duration_period) {
            data.duration_min_period = this.duration_period
            if (this.duration_max) data.duration_max_period = this.duration_period
          }
        }

        if (this.salary_min) {
          data.salary_min = this.salary_min
          if (this.salary_max) data.salary_max = this.salary_max
          if (this.salary_period) data.salary_period = this.salary_period
          if (this.salary_currency) data.salary_currency = this.salary_currency
          if (this.salary) data.salary = this.salary
        }

        if (this.xtramile_sector_id) data.xtramile_sector_id = this.xtramile_sector_id
        if (this.contract_schedule) data.contract_schedule = this.contract_schedule
        if (this.contract_type) data.contract_type = this.contract_type
        if (this.contract_remote) data.contract_remote = this.contract_remote
        if (this.contract_status) data.contract_status = this.contract_status

        if (this.experience_level) data.experience_level = this.experience_level
        if (this.experience_min) {
          data.experience_min = this.experience_min
          if (this.experience_max) data.experience_max = this.experience_max
          if (this.experience_period) {
            data.experience_min_period = this.experience_period
            if (this.experience_max) data.experience_max_period = this.experience_period
          }
        }
        if (this.experience_domains && this.experience_domains.length > 0) {
          data.experience_domains = this.experience_domains
        }
        if (this.diplomas && this.diplomas.length > 0) data.diplomas = this.diplomas
        if (this.education) data.education = this.education
        if (this.education_level) data.education_level = this.education_level
        if (this.education_languages && this.education_languages.length > 0) {
          data.education_languages = this.education_languages
        }
        if (this.education_domains && this.education_domains.length > 0) data.education_domains = this.education_domains

        this.$emit('childData', data)
        this.$emit('validation', true)
        this.$emit('next')
      } else {
        this.$emit('validation', false)
      }
    },
  },
}
</script>
